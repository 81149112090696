
.container {
  text-align: center;

  .box {
    display: inline-block;
    background-color: #fff;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;

    .art {
      margin-right: 40px;
    }

    .col {
      text-align: left;
      margin: 10px;
    }
  }
}